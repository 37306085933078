.semi-rounded {
  border-radius: 12px;
}

.notices {
  &__list {
    list-style-type: none;
    margin: 0;
    padding: 4px 0;
    li {
      padding-bottom: 6px;
      padding-top: 6px;
      border-bottom: 0.5px solid #2074B9;
      a {
        text-decoration: none;
        display: block;
        border-radius: 4px;
        color: white;
        &:hover {
          background-color: #2074B9;
          color: white;
        }
      }
    }
  }
  &__title {
    font-weight: bold;
    font-size: 18px;
  }
  &__tag, &__date{
    display: inline-block;
    span {
      color:#CBE7FF;
      font-size: 13px;
      font-weight: normal;
      margin-left: 5px;

    }
  }
  &__tag::before {
    content: "  ";
    width: 22px;
    height: 22px;
    background-image: '/images/tag.svg';
  }
}

.resources {
  &__list {
    list-style-type: none;
    margin: 0;
    padding: 4px 0;
    li {
      padding-bottom: 6px;
      padding-top: 6px;
      border-bottom: 0.5px solid #2074B9;
      a {
        text-decoration: none;
        display: block;
        border-radius: 4px;
        color: white;
        &:hover {
          background-color: #2074B9;
          color: white;
        }
      }
    }
  }
  &__title {
    font-weight: bold;
    font-size: 18px;
    word-break: break-word;
  }
  &__link {
    color: #00C571;
    word-break: break-word;
  }
}
