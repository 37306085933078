.btn {
    text-decoration: none;
    font-size: 14px;
}

.btn-outline-white {
    border: 1px solid #ffffff;
    margin-right: 10px;
    padding: 6px 15px !important;

    &.activeT,
    &:hover {
        background: #ffffff;
        color: #004E90 !important;

        svg path {
            fill: #004E90 !important;
        }
    }
}

.btn-green {
    background: #079558;
    color: #ffffff;
}
.btn-yellow {
    background: #FFC400;
    color: #004E90;
}
