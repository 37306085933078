.text {
  &-upper {
    text-transform: uppercase;
  }
  &-bold {
    font-weight: bold;
  }
}

.common-wrapper {
  padding: 18px 23px;
  border-radius: 10px;
}

.help-wrapper {
  @extend .common-wrapper;
}

.support-wrapper {
  @extend .common-wrapper;
  margin-right: 30px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.support-heading-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.support-heading {
  position: absolute;
  top: 50%;
  font-size: 40px;
  font-weight: bold;
  transform: translateY(-50%);
}

.w-70 {
  width: 70%;
}

.table-common {
  tr {
    border-bottom: 0.5px solid #2074b9;
    padding: 9px 0;
  }
}

.table-requestor,
.table-supporter,
.table-fulfilled {
  @extend .table-common;
}

.image-wrapper {
  display: flex;
  justify-content: center;
}

.request-fulfilled-wrapper {
  margin-top: 20px;
}

.tab-container {
  .nav-link {
    color: white;
    &.active {
      background-color: #ffd03a;
      color: #004e90;
      border-color: #ffd03a;
    }
  }
}

.w-60 {
  width: 60%;
}
.w-15 {
  width: 15%;
}
.w-25 {
  width: 25%;
}

.table-fulfilled-wrapper {
  width: 50%;
  @media (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
  }
}