@media(max-width: 991px) {
    .mobile-flag {
        display: inline;
    }

    .mob-view {
        display: inline-flex;
    }
    

    .emg-call {
        margin: 0 !important;

        .text {
            font-size: 12px;
        }
    }

    .desktop-view {
        display: none;
    }

    .menu-flag {
        display: none;
    }

    .navbar-dark  .navbar-nav {
        margin-left: 0%;
    }

    .navbar-collapse {
        height: calc(100vh - 80px);
        position: relative;
        overflow-y: auto;

        .btn {
            max-width: 280px;
            margin: 35px 0 0;
        }
    }

    .right-bar {
        height: calc(var(--vh, 1vh) * 100);
    }
}

@media(max-width: 550px) {
    .right-bar {
        
        
        .toggle-rightbar {
            right: 0;
            z-index: 1;
        }

        .nav-item {
            span {
                display: block;
            }
        }
    }

    .navbar-brand {
        line-height: 1.2;

        span {
            display: block;
            font-size: 16px;
        }
    }

    .navbar-toggler {
        padding: 0 0;
        border: 0;
    }

    .filter-wrapper {
        .search-wrapper {
            display: block;
        }

        .filter {
            margin: 10px 5px 0;
        }
    }

    .nav.nav-tabs {
        .nav-item {
            flex: 1 1;

            .nav-link {
                font-size: 18px !important;
            }
        }
    }

    .emg-call {
        .icon {
            display: none;
        }
    }

    .filter-wrapper .filter {
        vertical-align: top;
        > span {
            display: block;
            margin: 0 0 5px 0;
        }

        &:first-child {
            margin-right: 15px;
        }
    }

    .notices.nav.nav-tabs {
        .nav-item {
            width: 33.3333%;
            flex: auto;

            a {
                font-size: 16px !important;
                padding: 10px 10px;
                height: 100%;
            }
        }
    }
}

@media(max-width: 376px) {
    
    .right-bar {
        .content{
            padding-top: 32px;
        }
        .toggle-rightbar {
            top: 38px;
        }
    }

    .lang-selector {
        .dropdown-toggle {
            img {
                margin: 0 !important;
            }

            > span {
                display: none;
            }
        }
    }
}