

.symptoms {
    display: inline-block;
    min-height: 300px;
    min-width: 300px;
    position: relative;

    > img {
        display: block;
    }

    > div {
        position: absolute;
        font-size: 18px;

        &.left {
            left: 0;

            &-1 {
                left: 29%;
                font-weight: 800;
                font-size: 20px;
                top: 0;
            }

            &-2 {
                top: 22%;
            }

            &-3 {
                top: 46%;
                left: 3%;
            }

            &-4 {
                top: 72%;
                left: 24%;
                font-weight: 800;
            }

            &-5 {
                top: 86%;
                left: 1%;
                font-weight: 300;
                font-size: 16px;
            }

            &-6 {
                top: 93%;
                left: 40%;
            }
        }

        &.right {
            right: 0;

            &-1 {
                top: 72%;
                right: 10%;
                font-weight: 800;
            }

            &-2 {
                top: 33%;
                right: 6%;
            }
            
            &-3 {
                top: 13%;
                font-weight: 300;
                font-size: 16px;
            }
        }
    }
}

.symptoms-table {
    margin: 0;
    thead th {
        border-color: #FFFFFF;
        font-weight: 700;
    }
    tbody td {
        border: 0;
        padding: .55rem;
    }
}