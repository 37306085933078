.covid-notificatio {
    display: inline-block;

    .dropdown-toggle {
        position: relative;
        
        .new-notification {
            width: 6px;
            height: 6px;
            display: block;
            position: absolute;
            background: #FD3951;
            top: 2px;
            right: 5px;
        }

        &:after {
            display: none;
        }
    }

    .dropdown-menu {
        border-radius: 12px;
        font-size: 13px;
        min-width: 355px;
        max-width: 100%;

        .noti {
            &-title {
                font-size: 12px;
                font-weight: 700;
                border-bottom: 1px solid #000000;
                padding: 4px 8px;
            }

            &-content {
                max-height: 400px;
                overflow-y: auto;
                padding: 4px 12px;
            }
        }

        a {
            color: #004E90;
            padding: 6px 8px;
            border-bottom: 1px solid #4396DC;
            text-decoration: none;
            display: block;
            margin: 0;

            .time {
                display: inline-block;
                vertical-align: middle;
                color: #76B1E3;
                font-size: 10px;
            }

            .image-hold {
                width: 70px;
                border-radius: 12px;
                overflow: hidden;
            }

            &:last-child {
                border: 0;
            }

            &:hover {
                background: #76B1E3;
                color: #ffffff;

                .time {
                    color: #ffffff;
                }
            }
        }
    }
}