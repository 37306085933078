.prevention {
  &-title {
    margin-top: 2.8rem;
    font-size: 35px;
    font-weight: 600;
    color: #2074b9;
  }
  &-topic {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
    margin-top: 1rem;
  }
  &-subtopic {
    font-size: 14px;
    margin-bottom: 24px;
    margin-top: 24px;
  }
}
.reverse-row {
  flex-direction: row-reverse;
  @media screen and (max-width: 992px){
    flex-direction: row;
  }
}
.img-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-italic {
  font-style: italic;
}
.video {
  max-width: 100%;
}