

.search-wrapper {
    display: inline-block;
    vertical-align: middle;
    max-width: 200px;
    position: relative;

    input {
        padding-right: 25px;
        color: #ffffff !important;
        background: transparent !important;
        border-color: #ffffff;

        &::-webkit-input-placeholder { /* Edge */
            color: #ffffff;
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #ffffff;
        }
        
        &::placeholder {
            color: #ffffff;
        }
    }

    i {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        cursor: pointer;
        bottom: 0;
        padding: 3px 6px;
    }
}

.filter-wrapper {
    // background: #004E90;
    // position: sticky;
    top: 0;

    .filter {
        font-size: 12px;
        margin-left: 15px;

        span {
            margin: 0 8px 0 12px ;
            display: inline-block;
        }

        .filter-select {
            max-width: 112px;
            display: inline-block;
            font-size: 12px;
            width: 112px;
            vertical-align: middle;

            .css-1pahdxg-control,
            .css-yk16xz-control {
                min-height: 26px;

                .css-1gtu0rj-indicatorContainer,
                .css-tlfecz-indicatorContainer {
                    padding: 4px 5px 4px 0;
                }
                .css-1okebmr-indicatorSeparator {
                    margin: 0 2px 0 0px;
                }
            }

            .css-26l3qy-menu {
                color: #000000 !important;
            }

            .css-1hwfws3 {
                line-height: 1.32;
            }

        }
    }
}
